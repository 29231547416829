import '../scss/main.scss';
import variables from '../scss/variables/_variables.scss';

import { addBackToTop } from './vanilla-back-to-top';

addBackToTop({
  textColor: '#fff',
  backgroundColor: variables.brandColor,
  innerHTML: '<svg viewBox="0 0 80 40"><path d="M 0,40 L 40,0 L 80,40 z"/>'
});
